<template>
    <ion-page>
        <HeaderOne v-bind:name="$t('weather.name')" />
        <ion-content :fullscreen="true">
            <ion-header collapse="condense">
                <ion-toolbar>
                    <ion-title size="large">
                        {{$t('weather.name')}}
                    </ion-title>
                </ion-toolbar>
            </ion-header>

            <div v-if="error" class="loading">
                {{ error }}
            </div>

            <template v-if="weather != null">
                <h2 class="weather">{{ $t('weather.title') }}</h2>
                <ion-card>
                    <ion-card-content class="weather-header">
                        <h4>
                            {{ weather.weather[0].description }} <br>
                            {{ weather.main.temp }} &deg;C
                        </h4>
                        <img :src="'http://openweathermap.org/img/wn/' + weather.weather[0].icon + '@2x.png'" alt="Weather-Icon">
                    </ion-card-content>
                </ion-card>

                <ion-card class="weather-card">
                    <ion-card-title>
                        {{ weather.main.feels_like }} &deg;C
                    </ion-card-title>
                    <ion-card-subtitle>
                        {{ $t('weather.feels_like')}}
                    </ion-card-subtitle>
                </ion-card>

                <ion-card class="weather-card">
                    <ion-card-title>
                        {{ weather.main.temp_max }} &deg;C
                    </ion-card-title>
                    <ion-card-subtitle>
                        {{ $t('weather.high')}}
                    </ion-card-subtitle>
                </ion-card>

                <ion-card class="weather-card">
                    <ion-card-title>
                        {{ weather.main.temp_min }} &deg;C
                    </ion-card-title>
                    <ion-card-subtitle>
                        {{ $t('weather.low')}}
                    </ion-card-subtitle>
                </ion-card>

            </template>

        </ion-content>
    </ion-page>
</template>

<script lang="ts">
    import { IonPage, IonHeader, IonToolbar, IonCard, IonTitle, IonContent, IonCardTitle, IonCardSubtitle, IonCardContent, } from '@ionic/vue';
    import HeaderOne from '@/components/HeaderOne.vue';
    import { defineComponent } from 'vue';
    import axios from "axios";

    export default defineComponent({
        name: 'Wetter',
        components: { IonHeader, IonToolbar, IonTitle, IonPage, HeaderOne, IonCard, IonCardTitle, IonCardSubtitle, IonCardContent, IonContent, },
        data: function() {
            return {
                loading: false,
                error: null,
                weather: null,
            }
        },
        mounted() {
            this.error = this.weather = null
            axios.get('https://api.openweathermap.org/data/2.5/weather?id=2945358&appid=032830560a775b8dfebbe4fa772c5bf0&units=metric&lang=de', {
                timeout: 2000,
            })
                .then(response => {
                    this.weather = response.data;
                })
                .catch(function (err) {
                    this.error = err.toString();
                })
        },
    })
</script>

<style scoped>
    .loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 50%;
    }
    .weather {
        margin: 10px;
    }
    .weather-header {
        display: flex;
        align-items: center;
    }
    .weather-header > h4 {
        font-size: 18px;
        font-weight: bold;
    }
    .weather-card {
        padding: 16px 13px;
    }
</style>
