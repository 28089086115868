
    import { IonPage, IonHeader, IonToolbar, IonCard, IonTitle, IonContent, IonCardTitle, IonCardSubtitle, IonCardContent, } from '@ionic/vue';
    import HeaderOne from '@/components/HeaderOne.vue';
    import { defineComponent } from 'vue';
    import axios from "axios";

    export default defineComponent({
        name: 'Wetter',
        components: { IonHeader, IonToolbar, IonTitle, IonPage, HeaderOne, IonCard, IonCardTitle, IonCardSubtitle, IonCardContent, IonContent, },
        data: function() {
            return {
                loading: false,
                error: null,
                weather: null,
            }
        },
        mounted() {
            this.error = this.weather = null
            axios.get('https://api.openweathermap.org/data/2.5/weather?id=2945358&appid=032830560a775b8dfebbe4fa772c5bf0&units=metric&lang=de', {
                timeout: 2000,
            })
                .then(response => {
                    this.weather = response.data;
                })
                .catch(function (err) {
                    this.error = err.toString();
                })
        },
    })
